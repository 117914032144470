// jquery is needed globally
window.$ = window.jQuery = require('jquery')
// bootstrap javascript files

require('bootstrap');


$(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover({
        container: 'body'
    });

    jQuery.datetimepicker.setLocale('de');
    jQuery.datetimepicker.setDateFormatter('moment');
})

// Reload every 100 minutes to prevent token mismatch errors.
// This is reset after an interaction by the user.
let timeoutHandle = setTimeout(function () {
    location.reload();
}, 1000 * 60 * 100);

$('body').on('click', function(e) {
    window.clearTimeout(timeoutHandle);
    timeoutHandle = setTimeout(function () {
        location.reload();
    }, 1000 * 60 * 100);
})